module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bronzeon","short_name":"Bronzeon","start_url":"/","background_color":"#71432D","theme_color":"#71432D","display":"standalone","icon":"src/images/bronzeon-icon.png","icons":[{"src":"Icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"Icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"Icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"Icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"Icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"Icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"Icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"Icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4774d069678db8a19c5b82437ea652f4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
